<template>
    <v-container class="fill-height">
        <v-row align="center">
            <v-col
                sm="12" offset-sm="0"
                md="6" offset-md="1"
                lg="5" offset-lg="2"
                class="white--text"
            >
                <v-row><span class="text-h3">LearnSpirit</span></v-row>
                <v-row><span class="text-h4 mb-4">L'outil des formateurs</span></v-row>
                <v-row style="line-height: 25pt">Concevoir des formations n’a jamais été aussi simple. Laissez-vous guider et écrivez vos formations avec un découpage idéal de vos objectifs pédagogiques.</v-row>

                <v-divider class="mt-5 mb-7"></v-divider>

            </v-col>
            <v-col sm="12" md="5" lg="5">
                <v-card class="px-10 mx-4 rounded-lg">
                    <v-card-title class="justify-center text-h3">Connexion</v-card-title>
                    <v-text-field
                        class="rounded-l-xl"
                        outlined
                        dense
                        placeholder="Adresse email"
                        v-model="loginForm.email"
                        id="login_email"
                        append-icon="far fa-user"
                        @keydown.enter="login"
                    ></v-text-field>
                    <v-text-field
                        class="rounded-l-xl"
                        outlined
                        dense
                        placeholder="Mot de passe"
                        v-model="loginForm.password"
                        id="login_password"
                        append-icon="fas fa-lock"
                        type="password"
                        @keydown.enter="login"
                    ></v-text-field>
                    <v-btn
                        rounded
                        block
                        elevation="0"
                        class="black white--text mb-2"
                        @click="login"
                        :disabled="disabled"
                    >Connexion</v-btn>
                    <v-btn
                        rounded
                        block
                        elevation="0"
                        outlined
                        :to="{ name: 'Register', query: $route.query }"
                    >Inscription</v-btn>
                    <v-card-subtitle class="justify-center text-center"><router-link :to="{ name: 'Forgot', query: $route.query }" class="red--text text-decoration-none" >Mot de passe oublié ?</router-link></v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const { mapActions } = require("vuex");

export default {
    name: "Login",

    data: () => ({
        disabled: false,

        loginForm: {
            email: "",
            password: "",
        },

    }),

    computed: {
        redirect() {
            return this.$route.query.redirect;
        },
    },

    methods: {
        ...mapActions("global", ["addAlert"]),

        async login() {
            this.disabled = true;
            const alert = {};

            const response = await this.$store.dispatch("settings/login", this.loginForm);

            if(response.status === 200) {
                if(this.redirect === "subscription") {
                    await this.$router.push({ name: "Subscription" });
                } else {
                    await this.$router.push({ name: "Home" });
                }
            } else {
                alert.type = "error";
                alert.title = "Une erreur est survenue.";
                alert.message = "Merci de vérifier les informations rentrées.";
                alert.duration = 10000;

                this.loginForm.password = "";

                this.addAlert(alert);
                this.disabled = false;
            }
        },
    },
};
</script>

<style scoped>
</style>
